import React from "react";
import FlexContainer from "@components/FlexContainer/FlexContainer";
import Head from "next/head";
import { THEME_COLOR, WEBPAGE_TITLE } from "@utils/Constants";
import styles from "./FrontPage.module.scss";
import { Leaf } from "@components/Leaf/Leaf";
import { Button } from "@components/Button/Button";
import GetTranslatedStrings from "@helpers/GetTranslatedStrings";
import { CustomersSection } from "@components/CustomersSection/CustomersSection";
import { StatsSection } from "@components/StatsSection/StatsSection";
import { CompaniesInstitutionSection } from "@components/CompaniesInstitutionsSection/CompaniesInstitutionSection";
import { ReviewsSection } from "@components/ReviewsSection/ReviewsSection";
import { FeatureGridSection } from "@components/FeatureGridSection/FeatureGridSection";
import { useGeneralStatistics } from "@hooks/useGeneralStatistics";
const FrontPage = () => {
    const t = GetTranslatedStrings();
    const { data: statistics, error: statisticsError } = useGeneralStatistics();
    return (
        <>
            <Head>
                <title key="site_title">
                    {WEBPAGE_TITLE + " - Stafrænn sjálfbærnivettvangur"}
                </title>
                <meta
                    property="og:title"
                    content={`${WEBPAGE_TITLE} - Stafrænn sjálfbærnivettvangur`}
                    key="og:title"
                />
            </Head>

            <FlexContainer
                column
                justifyContent="center"
                alignItems="center"
                className={styles.container}
            >
                <Leaf className={styles.leaf1} plain />
                <Leaf className={styles.leaf2} plain />
                <Leaf className={styles.leaf3} plain />
                <div style={{ height: "25vh", minHeight: 200 }}></div>

                <h1 className={styles.mainText}>
                    Markviss og heildræn nálgun í sjálfbærnimálum
                </h1>
                <h2 className={styles.subText}>
                    Í hugbúnaði Laufsins má nálgast áhrifaríkar lausnir sem mæta
                    þörfum allra á vegferðinni að sjálfbærari rekstri, óháð
                    stærð og starfsgrein.
                </h2>

                <div style={{ paddingTop: "5vh" }}></div>
                <Button
                    className={styles.tryFreeButton}
                    size={"medium"}
                    href="https://meetings-eu1.hubspot.com/sveinborg-katla?uuid=dcf7faca-45c7-4528-855f-cc024a3b1be7"
                >
                    {t.try_free}
                </Button>
                <div style={{ paddingTop: "10vh" }}></div>
                <FeatureGridSection />
                <div
                    style={{
                        paddingTop: "20vh",
                        width: "100%",
                        background: "linear-gradient( #F6F6F6,#FFFFFF)",
                    }}
                />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        background: "white",
                        width: "100%",
                    }}
                >
                    <CustomersSection />
                </div>
                <div
                    style={{
                        paddingTop: "10vh",
                        width: "100%",
                        background: "white",
                    }}
                />
                <StatsSection statistics={statistics?.results} />
                <div style={{ background: "white", width: "100%" }}>
                    <CompaniesInstitutionSection />
                </div>
                <ReviewsSection />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",

                        paddingTop: "20vh",

                        background:
                            "linear-gradient(#fff, rgba(79, 200, 120, 0.44))",

                        paddingBottom: "10vh",
                    }}
                >
                    <h3
                        style={{
                            maxWidth: 500,
                            textAlign: "center",
                            marginTop: "10vh",
                            marginBottom: "7vh",
                            color: THEME_COLOR,
                            padding: "0px 20px",
                        }}
                    >
                        Viltu láta verk fylgja orðum í sjálfbærnimálum?
                    </h3>

                    <Button
                        className={styles.tryFreeButton}
                        size={"medium"}
                        href="https://meetings-eu1.hubspot.com/sveinborg-katla?uuid=dcf7faca-45c7-4528-855f-cc024a3b1be7"
                    >
                        {t.try_free}
                    </Button>
                </div>
            </FlexContainer>
        </>
    );
};

export default FrontPage;
